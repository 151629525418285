

import { defineComponent } from 'vue';
   
    import Navbar from './components/Nav.vue'
    import HeroSection from './components/Hero.vue'
    import AboutSection from './components/About.vue'
    import ContactSection from './components/Contact.vue'
    import ProjectsSection from './components/Projects.vue'
    import FooterSection from './components/Footer.vue'

export default defineComponent({
  name: 'App',
    components: {
        Navbar, 
        HeroSection,
        AboutSection,
        ContactSection,
        ProjectsSection,
        FooterSection,
    },

    data: () => {
        return {
           
        }
    },
     
    methods: {
        
    },

    created() {
      
    },

  
});

<template>
    <div class="footer-container">
        <div class="social">
            <a href="https://github.com/DylanKral" target="_blank">
                <i class="fab fa-github"></i>
            </a>
            <a href="https://www.instagram.com/dylan_kral/" target="_blank">
                <i class="fab fa-instagram"></i>
            </a>
            <a href="https://www.linkedin.com/in/dylan-kral" target="_blank">
                <i class="fab fa-linkedin"></i>
            </a>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'FooterSection',
    }
</script>

<style scoped>
    .footer-container{
        background: gray;
        padding: 1em;
    }

    .social{
        display:flex;
        justify-content:center;
        gap: 3em;
    }

    i {
        font-size: 2em;
        cursor:pointer;
    }

    a{
        text-decoration:none;
        color:black;
    }
</style>
<template>
    <div class="about-container" id="about-me">
        <div class="about-section">
            <h2 id="about">About Me</h2>
            <p id="text">
                Hi, I'm <b>Dylan Kral</b>, an aspiring Developer. Recent graduate from California State University San Marcos with a Bachelors in Computer Science.
                Some of the languages that I've worked with are C++, Html, CSS, SQL, Kotlin, Python, and JS. I'm passionate about software development,
                and am committed to delivering exceptional results for my clients. When I'm not working, you can find
                me working on my car, building computers or being at the gym! I look forward to connecting with you!
            </p>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'AboutSection',
    }
</script>

<style scoped>

    @import url('https://fonts.googleapis.com/css2?family=Barlow:wght@200&family=Lato:wght@300&display=swap');
    @import url('https://fonts.googleapis.com/css2?family=Lato:wght@300&family=Rubik:wght@300;400&display=swap');

    .about-container {
        box-sizing: border-box;
        margin: 0;
        background-color: #EFF1F3;
        position: relative;
        min-width: 250px;
        width: 100vw;
        text-align: center;
    }

    .about-section{
       margin: 0 auto;
       padding: 2em 1rem;
    }

    #about{
        width: fit-content;
        border-bottom: 2px solid;
        margin-bottom: 30px;
        margin-left: auto;
        margin-right: auto;
        font-family: rubik, sans-serif;
    }

    #text{
        margin: auto;
       justify-content: center;
       text-align:center;
       line-height: 30px;
       max-width: 500px;
       font-size: 1.25em;
    }
</style>
import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "page" }
const _hoisted_2 = { class: "NavBar" }
const _hoisted_3 = { class: "Hero" }
const _hoisted_4 = { class: "About" }
const _hoisted_5 = { class: "Project" }
const _hoisted_6 = { class: "Contact" }
const _hoisted_7 = { class: "Footer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Navbar = _resolveComponent("Navbar")!
  const _component_HeroSection = _resolveComponent("HeroSection")!
  const _component_AboutSection = _resolveComponent("AboutSection")!
  const _component_ProjectsSection = _resolveComponent("ProjectsSection")!
  const _component_ContactSection = _resolveComponent("ContactSection")!
  const _component_FooterSection = _resolveComponent("FooterSection")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_Navbar)
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_HeroSection)
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createVNode(_component_AboutSection)
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createVNode(_component_ProjectsSection)
    ]),
    _createElementVNode("div", _hoisted_6, [
      _createVNode(_component_ContactSection)
    ]),
    _createElementVNode("div", _hoisted_7, [
      _createVNode(_component_FooterSection)
    ])
  ]))
}
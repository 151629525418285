<template>
    <div class="contact-container">
       
        <div class="contact-card">
            <h2 class="contact" id="contact">
                Contact Me
            </h2>

            <form ref="form" @submit.prevent="sendEmail">
                <p class="label" id="name">Name</p>
                <input type="text" id="user_name" name="user_name" placeholder="Enter Name" required/>
                <p class="label" id="email">Email</p>
                <input type="email" id="user_email" name="user_email" placeholder="Enter Email" required/>
                <p class="label" id="message">Message</p>
                <textarea id="text-area" name="message" rows="4" placeholder="Enter Message Here" required></textarea>
                <input type="submit" id="submit" value="Send" />
            </form>
        </div>
    </div>
   
</template>



<script>
    import emailjs from '@emailjs/browser';

    export default {
        name: 'ContactSection',

        methods:{
            sendEmail(){{

                emailjs.sendForm('Dylan Kral Portfolio', 'contact',this.$refs.form, 'TZEJsKQ0p5YFXsRlO')
                    .then((result) => {
                        console.log('SUCESS!', result.text);
                        this.$refs.form.reset();
                    }, (error) => {

                        console.log('FAILED', error.text);
                    });
            }}

        }

    }
</script>

<style scoped>

    @import url('https://fonts.googleapis.com/css2?family=Lato:wght@300&family=Oswald:wght@200;300;400&display=swap');
    @import url('https://fonts.googleapis.com/css2?family=Barlow:wght@200&family=Lato:wght@300&display=swap');
    @import url('https://fonts.googleapis.com/css2?family=Lato:wght@300&family=Rubik:wght@300;400&display=swap');

    .contact-container {
        display: flex;
        justify-content: center;
        height: 500px;
        padding: 50px 0 100px 0;
        background-color: #EFF1F3;
        position: relative;
        min-width: 300px;
        width: 100%;
    }

    .contact-card {
        display: inline-block;
        margin: auto;
        justify-content: center;
        text-align: center;
        box-shadow: 0px 0px 1rem 10px rgba(0,0,0,.5);
        border-radius: 15px;
        padding: 2em 3em 2em 3em;
        min-width: 200px;
        max-width: 350px;
        transition: .5s ease-in-out;
    }

    @media (max-width: 370px ){
        .contact-card{
            padding: 1em;
        }
    }

    .label {
        margin-right: 60%;
    }


    input, textarea {
        display: flex;
        margin: auto;
        margin-bottom: 1em;
        border-radius: 15px;
        border: 2px solid rgba(0,0,0,.5);
        padding: .5rem;
        font-family: 'Lato', sans-serif;
        font-weight: 400;
        font-size: 20px;
        transition: .3s ease all;
    }

        input:focus, textarea:focus {
            border: double 3px transparent;
            border-radius: 6px;
            background-image: linear-gradient(white, white), linear-gradient(to right, #12B3EB, #5460F9);
            background-origin: border-box;
            background-clip: padding-box, border-box;
        }

    #user_name, #user_email, #text-area {
        outline: none;
        width: 250px;
    }

    #name {
        margin-top: 1em;
    }

    #text-area {
        resize: none;
        overflow: hidden;
    }

    .custom-shape-divider-top-1680892180 {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        overflow: hidden;
        line-height: 0;
    }

    .custom-shape-divider-top-1680892180 svg {
            position: relative;
            display: block;
            width: calc(100% + 1.3px);
            height: 44px;
    }

    .custom-shape-divider-top-1680892180 .shape-fill {
            fill: #33658A;
    }
</style>

    import { defineComponent } from 'vue';

  export default defineComponent({
      name: 'NavBar',
      props: {

      }, 

      data() {
          return {
              scrolledNav: false,
              mobile: true,
              mobileNav: false,
              windowWidth: 750,
              miniNav: false,
          }
      },
      methods: {
          toggleMobileNav(){
              this.mobileNav = !this.mobileNav;
          },

          updateScroll() {
              const scrollPosition = window.pageYOffset;

              if (scrollPosition >= 200) {
                  this.scrolledNav = true;
                  this.miniNav = true;
                 
              }
              this.miniNav = false;
              this.scrolledNav = false;
          },

          checkScreen() {
              this.windowWidth = window.innerWidth;

              if (this.windowWidth < 610) {
                  this.mobile = true;
              }

              else {
                  this.mobile = false;
                  this.mobileNav = false;
              }
          }
      },

      mounted() {
          window.addEventListener('scroll', this.updateScroll);
      },

      created() {
         window.addEventListener("resize", this.checkScreen);
         this.checkScreen();
      }

      ,
      beforeUnmounted (){
          window.removeEventListener("scroll", this.updateScroll)
      }

    });

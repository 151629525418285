
import { defineComponent } from 'vue';


export default defineComponent({
  name: 'ProjectsSection',
  
  data() {
    return {
    
    };
  },

  mounted(){
    
  }
});

<template>

    <div class="heroContainer" id="hero">
        <div class="intro-text">
            <h1 id="title" class="typed-out">Hello, I'm Dylan</h1>
            <p id="developer" class="typed-out">I'm a Developer Located in Southern California</p>
            <a id="reach-out-button" href="#contact">Reach Out</a>
        </div>
        <img id="self-portrait" src="../assets/SelfPortrait.png" width="600" height="600" alt="Self Image" />

        <div class="custom-shape-divider-bottom-1680890047">
            <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
                <path d="M0,0V46.29c47.79,22.2,103.59,32.17,158,28,70.36-5.37,136.33-33.31,206.8-37.5C438.64,32.43,512.34,53.67,583,72.05c69.27,18,138.3,24.88,209.4,13.08,36.15-6,69.85-17.84,104.45-29.34C989.49,25,1113-14.29,1200,52.47V0Z" opacity=".25" class="shape-fill"></path>
                <path d="M0,0V15.81C13,36.92,27.64,56.86,47.69,72.05,99.41,111.27,165,111,224.58,91.58c31.15-10.15,60.09-26.07,89.67-39.8,40.92-19,84.73-46,130.83-49.67,36.26-2.85,70.9,9.42,98.6,31.56,31.77,25.39,62.32,62,103.63,73,40.44,10.79,81.35-6.69,119.13-24.28s75.16-39,116.92-43.05c59.73-5.85,113.28,22.88,168.9,38.84,30.2,8.66,59,6.17,87.09-7.5,22.43-10.89,48-26.93,60.65-49.24V0Z" opacity=".5" class="shape-fill"></path>
                <path d="M0,0V5.63C149.93,59,314.09,71.32,475.83,42.57c43-7.64,84.23-20.12,127.61-26.46,59-8.63,112.48,12.24,165.56,35.4C827.93,77.22,886,95.24,951.2,90c86.53-7,172.46-45.71,248.8-84.81V0Z" class="shape-fill"></path>
            </svg>
        </div>
    </div>
   

</template>

<script>
    export default {
        name: 'heroSection',

    }
</script>

<style scoped>
    @import url('https://fonts.googleapis.com/css2?family=Lato:wght@300&family=Oswald:wght@200;300;400&display=swap');
    @import url('https://fonts.googleapis.com/css2?family=Barlow:wght@200&family=Lato:wght@300&display=swap');
    @import url('https://fonts.googleapis.com/css2?family=Lato:wght@300&family=Rubik:wght@300;400&display=swap');


    .heroContainer {
        display: flex;
        flex-flow: row wrap;
        font-family: 'Rubik', sans-serif;
        background: black;
        width: 100%;
        height: 800px;
        overflow: hidden;
        position:relative;
    }

    .intro-text{
        margin-left: 20%;
        margin-right:100px;
    }

    h1{
        margin-top: 95px;
        color: white;
        font-weight: 400;
        font-size: 3em;
    }

    #developer{
        text-align: center;
        margin-top: 15px;
        margin-left: 10px;
        margin-bottom: 40px;
        color:white;
        width: 300px;
        font-size: 1.5em
    }

    #reach-out-button{
        width: fit-content;
        margin-left:100px;
        padding: 1em;
        background: rgba(0,0,0,0);
        color: white;
        font-family: 'Barlow', sans-serif;
        font-weight:300;
        border: 2px solid white;
        border-radius: 1rem;
        cursor:pointer;
        
    }

    #self-portrait {
        position: relative;
        margin: -85px 0 0 40%;
        transition: .5s ease all;
    }
    
    @media (max-width: 609px){
        #self-portrait{
            position:relative;
            width: 400px;
            height: 400px;
            margin: 0 auto;
            transition: .7s ease all;
            top: 50px;
        }

        .intro-text {
            margin: 0 auto;
        }
    }
    .custom-shape-divider-bottom-1680890047 {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        overflow: hidden;
        line-height: 0;
        transform: rotate(180deg);
    }

        .custom-shape-divider-bottom-1680890047 svg {
            position: relative;
            display: block;
            width: calc(100% + 1.3px);
            height: 44px;
        }

        .custom-shape-divider-bottom-1680890047 .shape-fill {
            fill: #EFF1F3;
        }
    a{
        text-decoration: none;
    }
</style>